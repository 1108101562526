import React from 'react'
import { Layout } from '../components/layout/Layout'
import { SiteHeader } from '../components/site-header/SiteHeader'
import { SiteFooter } from '../components/site-footer/SiteFooter'
import { HeadingMd } from '../components/heading/Heading'
import { ContainerMd } from '../components/container/Container'
import { ContactBlock } from '../components/contact-block/ContactBlock'
import { Wysiwyg } from '../components/wysiwyg/Wysiwyg'
import { Cards } from '../components/cards/Cards'

const ContactPage = () => {
  return (
    <Layout title="Contact" desc="De mondhygiënist richt zich op preventie en helpt bij het voorkomen van tandbederf en tandvleesproblemen.">
      <SiteHeader />

      <ContainerMd>
        <Wysiwyg>
          <HeadingMd h={2}>
            Heeft u nog vragen?
            <br /><span>U kunt ons bereiken via één van onderstaande manieren.</span>
          </HeadingMd>
        </Wysiwyg>
      </ContainerMd>

      <ContainerMd>
        <ContactBlock />
      </ContainerMd>

      <Cards />

      <SiteFooter />
    </Layout>
  )
}

export default ContactPage
