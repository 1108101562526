import React from "react"
import { HeadingXs } from '../heading/Heading'
import * as styles from './ContactBlock.module.css'

export function ContactBlock () {
  return (
    <div className={styles.component}>
      <div>
        <HeadingXs h={2}>Telefoon</HeadingXs>
        <p><a href="tel:+31355242454">+31 (0)35 524 2454</a></p>
      </div>
      <div>
        <HeadingXs h={2}>E-mail</HeadingXs>
        <p><a href="mailto:fjlambooy@mondhygieniste-huizen.nl">fjlambooy@mondhygieniste-huizen.nl</a></p>
      </div>
      <div>
        <HeadingXs h={2}>Adres</HeadingXs>
        <address>
          Gooisekust 74<br />
          1274 JH Huizen
        </address>
      </div>
      <div>
        <HeadingXs h={2}>KVK-nummer</HeadingXs>
        <p>32155896</p>
      </div>
    </div>
  )
}
